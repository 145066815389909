var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1466ef71d5d82a1631094b4288db5b0668b90a9d"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const isDev = process.env.NODE_ENV === 'development';

// 创建一个 Map 来存储最近发送的错误及其计数
const errorCache = new Map<string, { count: number; timestamp: number }>();

// 定义去重的时间窗口（毫秒）
const DEDUPLICATION_WINDOW = 60000; // 1分钟

// 定义窗口时间内的最大请求次数
const MAX_REQUESTS_PER_WINDOW = 10;

Sentry.init({
  dsn: "https://b618fc35c32cc7cc906e154b2decebfb@o274112.ingest.us.sentry.io/4508143890202624",

  // 定义采样率。在生产环境中调整此值，或使用 tracesSampler 以获得更精细的控制。
  tracesSampleRate: isDev ? 0 : 1,
  sampleRate: isDev ? 0.1 : 1,

  // 将此选项设置为 true 将在控制台打印有用的信息，帮助你设置 Sentry。
  debug: false,

  beforeSend(event) {
    const stacktrace = event.exception?.values?.[0]?.stacktrace;
    const errorSignature = `${event.message}:${stacktrace?.frames?.[0]?.filename}:${stacktrace?.frames?.[0]?.lineno}`;
    const now = Date.now();

    // 清理旧的缓存条目
    for (const [key, data] of errorCache.entries()) {
      if (now - data.timestamp > DEDUPLICATION_WINDOW) {
        errorCache.delete(key);
      }
    }

    const errorData = errorCache.get(errorSignature);

    if (errorData) {
      if (now - errorData.timestamp < DEDUPLICATION_WINDOW) {
        // 如果在去重时间窗口内，检查是否超过最大请求次数
        if (errorData.count >= MAX_REQUESTS_PER_WINDOW) {
          // 如果超过最大请求次数，则不发送
          return null;
        }
        // 增加计数
        errorData.count += 1;
      } else {
        // 如果超出时间窗口，重置计数和时间戳
        errorData.count = 1;
        errorData.timestamp = now;
      }
    } else {
      // 如果是新错误，添加到缓存
      errorCache.set(errorSignature, { count: 1, timestamp: now });
    }

    return event;
  },
});
