import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'

export const initGA = () => {
  // 设置 GA code
  ReactGA.initialize('G-B0BG6J2K56')
}

export const initGTM = () => {
  TagManager.initialize({
    gtmId: process.env.gtmId || ''
  })
}

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname })
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: window.document.title });
}
