import React, { useEffect } from 'react'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { NextIntlClientProvider } from 'next-intl'
import { MDXProvider } from '@mdx-js/react'
import { Helmet } from 'react-helmet'
import { Toaster } from 'react-hot-toast'

import { initGA, logPageView } from '@/utils/ga'
import '@/styles/index.css'
// import en from '../messages/en.json'
// import zh from '../messages/zh.json'

// import * as Sentry from "@sentry/nextjs";

// Sentry.init({
//   dsn: "https://b618fc35c32cc7cc906e154b2decebfb@o274112.ingest.us.sentry.io/4508143890202624",

//   // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
//   tracesSampleRate: 1,

//   // Setting this option to true will print useful information to the console while you're setting up Sentry.
//   debug: false,
// });
import logo from '@/public/logo.svg'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    if (process.env.gaId) {
      initGA()
      logPageView()
      router.events.on('routeChangeComplete', logPageView)

      return () => {
        router.events.off('routeChangeComplete', logPageView)
      }
    }
  }, [router])

  const meta = [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
  ]

  const link = [
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: logo
    }
  ]

  const components = {
    wrapper: (props: React.ComponentProps<'article'>) => (
      <article className="markdown-body border border-border p-6 rounded !my-6 bg-white" {...props} />
    ),
  }

  if (process.env.baiduToken) {
    meta.push({
      name: 'baidu-site-verification',
      content: process.env.baiduToken
    })
  }

  return (
    <NextIntlClientProvider
      locale={router.locale}
      timeZone="Asia/Shanghai"
      messages={pageProps.messages}
    >
      <MDXProvider components={components}>
        <Helmet
          htmlAttributes={{ lang: 'zh' }}
          meta={meta}
          link={link}
        />
        <Toaster
          toastOptions={{
            className: '!max-w-[420px] text-sm text-gray-700',
          }}
        />
        <Component {...pageProps} />
      </MDXProvider>
    </NextIntlClientProvider>
  )
}

export default MyApp
